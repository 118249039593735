import main from 'base/main.js'

import App from './components/App'
import router from './router'
import store from './store'

const code = 'bk'
const title = 'Витрина компании "БалтКам"'
const pageTitle = 'Витрина компании "БалтКам"'
const region = 'ru'

router.beforeEach((to, from, next) => {
  if (typeof to.meta !== 'undefined' && typeof to.meta.title !== 'undefined') {
    document.title = [pageTitle, to.meta.title].join(' - ')
  } else {
    document.title = pageTitle
  }

  next()
})

const app = main.getInstance({ App, router, store, code, region, title })

export default app
